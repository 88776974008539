<template>
    <div class="main-menu-navigation" :class="{mobile: mob.is}">
        <template v-if="mob.is">
            <ul class="mob-menu0" :style="{transform: translate}">
                <li><a href="/" title="" @click.prevent="goToLink('/')">Home</a></li>
                <template v-if="init">
                    <li v-for="(level0, index0) in categories" :key="index0">
                        <a href="#" title="" @click.prevent="getLength(level0) > 0 ? stepIn(index0, -1, -1) : goTo(getKey(level0))" :class="{active: count == index0, hassubmenu: (getLength(level0) > 0)}">{{ getKey(level0) }}</a>
                        <div class="mob-menu1-wrapper" v-if="getLength(level0) > 0" :class="{active: index0 == count}">
                            <ul class="mob-menu1">
                                <li><a href="#" title="" class="step-back" @click.prevent="stepBack(-1, -1, -1)">{{ getKey(level0) }}</a></li>
                                <template v-for="level1 in level0">
                                    <li v-for="(level2, index1) in level1" :key="index1" :class="{active: index1 == count1}">
                                        <a href="#" title="" @click.prevent="getLength(level2) > 0 ? stepIn(index0, index1, -1) : goTo(getKey(level0) + '.' + getKey(level2))" :class="{hassubmenu: (getLength(level2) > 0)}">{{ getKey(level2) }}</a>
                                        <div class="mob-menu2-wrapper" v-if="getLength(level2) > 0" :class="{active: (index0 == count && index1 == count1)}">
                                            <ul class="mob-menu2">
                                                <li><a href="#" title="" class="step-back" @click.prevent="stepBack(index0, -1, -1)">{{ getKey(level2) }}</a></li>
                                                <template v-for="level3 in level2">
                                                    <li v-for="(level4, index2) in level3" :key="index2">
                                                        <a href="#" title="" @click.prevent="goTo(getKey(level0) + '.' + getKey(level2) + '.' + getKey(level4))">{{ getKey(level4) }}</a>
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </li>
                </template>
                <li><a href="/videos" title="" @click.prevent="goToLink('/videos')">Videos</a></li>
            </ul>
        </template>
        <template v-else>
            <ul class="top-menu" @mouseleave="deactivateLevel(-1)">
                <li><a href="/" title="" @click.prevent="goToLink('/')" @mouseover="activateLevel(1, -1)">Home</a></li>
                <template v-if="init">
                    <li v-for="(level0, index0) in categories" :key="index0">
                        <a :href="createStaticPath(getKey(level0))" title="" @mouseover="activateLevel(1, index0)" :class="{active: count == index0}" @click.prevent="goTo(getKey(level0))">{{ getKey(level0) }}</a>
                        <div class="submenu" v-if="getLength(level0) > 0" :class="{active: index0 == count}">
                            <ul class="submenu-list">
                                <template v-for="level1 in level0">
                                    <li v-for="(level2, index1) in level1" :key="index1" :class="{active: index1 == count1}">
                                        <a :href="createStaticPath(getKey(level0) + '.' + getKey(level2))" title="" @mouseover="activateLevel(2, index1)" @click.prevent="goTo(getKey(level0) + '.' + getKey(level2))" :class="{hassubmenu: (getLength(level2) > 0)}">{{ getKey(level2) }}</a>
                                        <div class="sub-submenu" v-if="getLength(level2) > 0" :class="{active: (index0 == count && index1 == count1)}" v-render="{index0}">
                                            <ul>
                                                <template v-for="level3 in level2">
                                                    <li v-for="(level4, index2) in level3" :key="index2">
                                                        <a :href="createStaticPath(getKey(level0) + '.' + getKey(level2) + '.' + getKey(level4))" title="" @click.prevent="goTo(getKey(level0) + '.' + getKey(level2) + '.' + getKey(level4))">{{ getKey(level4) }}</a>
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </li>
                </template>
                <li class="single-level"><a href="/videos" title="" @click.prevent="goToLink('/videos')" @mouseover="activateLevel(1, -1)">Videos</a></li>
                <li class="single-level"><a href="/webinars" title="" @click.prevent="goToLink('/webinars')" @mouseover="activateLevel(1, -1)">Webinars</a></li>
                <li class="single-level"><a href="/article/service-status" title="" @click.prevent="goToLink('/article/service-status')" @mouseover="activateLevel(1, -1)">Status</a></li>
            </ul>
            <div class="search-trigger">
                <button class="trigger" @click="triggerSearchView" :class="{active: isSearchActive}"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Wrapper (Required)">
<path id="Union (Required)" d="M14.7199 13.0667L11.82 10.1667C11.62 9.96667 11.56 9.65334 11.6933 9.4C12.2733 8.30667 12.5 7 12.2 5.62C11.7467 3.50667 10.02 1.82667 7.89329 1.43334C4.02662 0.713337 0.706658 4.03334 1.43332 7.9C1.83332 10.02 3.51327 11.7467 5.6266 12.2067C7.0066 12.5 8.3133 12.28 9.40663 11.7C9.65996 11.5667 9.97331 11.62 10.1733 11.8267L13.0733 14.7267C13.3333 14.9867 13.7533 14.9867 14.0133 14.7267L14.7199 14.02C14.9799 13.76 14.9799 13.34 14.7199 13.08V13.0667ZM2.66664 6.83334C2.66664 4.53334 4.53331 2.66667 6.83331 2.66667C9.13331 2.66667 11 4.53334 11 6.83334C11 9.13334 9.13331 11 6.83331 11C4.53331 11 2.66664 9.13334 2.66664 6.83334Z" fill="black"/>
</g>
</svg></button>

            </div>            
        </template>

    </div>
</template>

<script>
import store from '../store';
import router from '../router';

export default {
    name: 'mainMenuNavigation',
    props: ['mob'],
    data() {
        return {
            active: false,          // is menu currently active
            activeLevel: 0,         // the active depth
            itemNumber1: 0,         // top level item number
            itemNumber2: 0,         // sublevel item number    
            submenuHeight: 0,       // height of the level2 menu so we can show appropriate height of the dropdown

            searchActive: false,    // is search area active and visible
            init: false,

            count: -1,
            count1: -1,
            count2: -1,

            translate: 'TranslateX(0)',
        }
    },

    mounted() {
        window.addEventListener('resize', this.adjustSizes);
    },

    computed: {
        categories() {
            return store.state.categories;
        },

        isMobile() {
            return this.mob.is;
        },

        isSearchActive() {
            return store.state.searchStatus;
        }
    },

    watch: {
        categories(n, o) {
            if(n.length != o.length && n.length > 0) {
                this.init = true;
            }
        },

        isMobile() {
            this.count = -1;
            this.count1 = -1;
            this.count2 = -1;
            this.translate = 'TranslateX(0)';
        },

        isSearchActive(n) {
            n ? (this.searchActive = true) : (this.searchActive = false);
        }
    },

    directives: {
        render: {
            inserted(el, data) {
                if(data.value.index0 == 2) {
                    return false;
                }

                let parentHeight = document.querySelectorAll('.submenu-list')[data.value.index0].clientHeight;
                let childHeight = el.clientHeight;
                let elHeight = parentHeight > childHeight ? parentHeight : childHeight;

                //document.getElementsByClassName('submenu')[data.value.index0].style.height = (el.clientHeight + 91) + 'px';
                document.getElementsByClassName('submenu')[data.value.index0].style.height = (elHeight + 70) + 'px';
            }            
        }
    },

    methods: {

        adjustSizes() {
            //!this.mob.is ? this.stepIn(-1, -1, -1) : this.stepIn(this.count, this.count1, this.count2);
            this.stepIn(this.count, this.count1, this.count2);
        },

        stepIn(level0, level1, level2) {
            if(level0 != -1) {
                this.count = level0;
                this.translate = 'TranslateX(-' + this.mob.width + 'px)';

                if(level1 != -1) {
                    this.count1 = level1;
                    this.translate = 'TranslateX(-' + (this.mob.width*2) + 'px)';

                    if(level2 != -1) {
                        this.count2 = level2;  
                        this.translate = 'TranslateX(-' + (this.mob.width*3) + 'px)';            
                    }                    
                }                
            }
        },

        stepBack(level0, level1, level2) {
            if(level2 == -1) {
                this.count2 = -1;
                this.translate = 'TranslateX(-' + (this.mob.width*2) + 'px)';
            }

            if(level1 == -1) {
                this.count1 = -1;
                this.translate = 'TranslateX(-' + this.mob.width + 'px)';
            }

            if(level0 == -1) {
                this.count = -1;
                this.translate = 'TranslateX(0)';
            }
        },

        getKey(obj) {
            let prop = '';
            for(var key in obj) {
                //console.log(key);
                prop = key;
            }
            return prop;
        },

        getLength(obj) {
            let length = 0;
            for(var key in obj) {
                length = obj[key].length;
            }
            return length;
        },

        activateLevel(level, index) {
            if(level == 1) {
                
                //console.log('level 1');
                //console.log('index ' + index);
                //console.log(document.querySelectorAll('.submenu-list')[data.value.index0].clientHeight);

                this.count = index;
                this.count1 = -1;
            }
            if(level == 2) {
                
                //console.log('level 2');
                //console.log('index ' + index);
                
                this.count1 = index;
            }
            /*
            console.log(document.querySelectorAll('.submenu-list')[this.count].clientHeight);
            let elHeight = document.querySelectorAll('.submenu-list')[this.count].clientHeight;
            document.getElementsByClassName('submenu')[this.count].style.height = (elHeight + 70) + 'px';
            */
        },

        deactivateLevel(level) {
            if(level == -1) {
                this.count = -1;
                this.count1 = -1;
            }            
            if(level == 1) {
                this.count = -1;
            }
            if(level == 2) {
                this.count1 = -1;
            }
        },

        triggerSearchView() {
            this.searchActive = !this.searchActive;
            store.dispatch('setSearchStatus', this.searchActive);
        },

        goTo(path) {
            // path - Administration.Business Settings
            store.dispatch('setCatDestination', 'Root.' + path);
            let url = path;
            url = url.toLowerCase();
            url = url.replace(/\./g, '/');
            url = url.replace(/ /g, '-');

            this.count = -1;
            this.count1 = -1;
            this.count2 = -1;

            this.translate = 'TranslateX(0)';
            router.push({path: '/' + url});
        },

        goToLink(link) {
            router.push({path: link});
        },

        /*
        goHome() {
            router.push({path: '/'});
        },
        */

        createStaticPath(path) {
            let url = path;
            url = url.toLowerCase();
            url = url.replace(/\./g, '/');
            url = url.replace(/ /g, '-');
            return '/' + url;
        }
    }
}
</script>

<style scoped>

    .main-menu-navigation {
        max-width: 930px;
        margin: 0 auto;
    }

    .main-menu-navigation.mobile {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 100;
    }

    .mob-menu0 {
        transform: translateX(0);
        transition: all .3s;
    }

    .mob-menu0 a.hassubmenu:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: block;
        height: 6px;
        right: 30px;
        position: absolute;
        width: 6px;
        top: 50%;
        margin: auto;
        transform: translateY(-50%) rotate(45deg);
        color: var(--everyday-green);
    }

    .mob-menu0 a:hover {
        cursor: pointer;
    }

    .mob-menu0, .mob-menu1, .mob-menu2 {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .mob-menu0 li a, .mob-menu1 li a, .mob-menu2 li a {
        display: block;
        background: #ffffff;
        padding: 18px;
        border-bottom: 1px solid #bfbfbf;
        position: relative;
    }

    .step-back {
        text-align: center;
    }

    .step-back:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: block;
        height: 6px;
        position: absolute;
        width: 6px;
        top: 50%;
        margin: auto;
        color: #000000;
        transform: translateY(-50%) rotate(-135deg);
        left: 30px;
    }

    .mob-menu1-wrapper {
        z-index: 50;
        display: none;
        position: absolute;
        width: 100%;
        top: 0;
        left: 100%;
    }

    .mob-menu1-wrapper.active {
        display: block;
    }

    .mob-menu2-wrapper {
        z-index: 100;
        display: none;
        position: absolute;
        width: 100%;
        top: 0;
        left: 100%;
    }

    .mob-menu2-wrapper.active {
        display: block;
    }





























    .top-menu {
        margin: 0 50px 0 0;
        padding: 0;
        list-style: none;
    }

    .top-menu li {
        display: inline-block;
        line-height: 48px;
    }

    .top-menu li a {
        color: #ffffff;
        display: block;
        padding: 0 30px 0 10px;
        position: relative;
        transition: all .3s;
        font-size: 1em;
        font-weight: 300;
    }

    .top-menu li a:hover, .top-menu li a.active  {
        background: #ffffff;
        color: var(--charcoal);
        text-decoration: none;
    }

    .top-menu li:first-child a, .top-menu li:last-child a {
        padding: 0 10px 0 10px;
    }

    .submenu li:first-child a, .sub-submenu li:first-child a {
        padding: 0;
    }

    .top-menu li:first-child a:after, .top-menu li:last-child a:after {
        display: none;
    }

    .top-menu li.single-level a {
        padding: 0 10px;
    }

    .top-menu li.single-level a:after {
        display: none;
    }

    .top-menu li a:after {
        content: '';
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        background-image: url('./../assets/images/ChevronDown.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        /* margin: 0 0 -1.5px 4px; */
        top: 36%;
        right: 8px;
        filter: invert(1);
    }

    .top-menu li a:hover:after {
       /*color: var(--charcoal);*/
       filter: invert(0);
       transform: rotate(-180deg);
    }
    
    .submenu {
        /*transition: .2s height ease, .3s opacity ease;*/
        transition: all .3s;
        position: absolute;
        top: 48px;
        background: #ffffff;
        box-shadow: 0 9px 10px rgba(0,0,0,0.2);
        left: 0;
        right: 0;
        z-index: 100;
        backface-visibility: hidden;
        padding: 35px 0 35px 0;

        opacity: 0; /* change this */
        visibility: hidden;
        /*
        display: none;
        height: 0;
        */
    }

    .submenu.active {
        opacity: 1;
        visibility: visible;
    }

    .submenu ul {
        margin: 0;
        padding: 0;
    }

    .submenu .submenu-list {
        max-width: 930px;
        margin: 0 auto;
        position: relative;
    }

    .submenu .submenu-list li:last-child a {
        padding: 0;
    }

    .submenu ul li {
        display: block;
        line-height: 40px;
        width: 280px;
        padding-right: 20px;
        transition: 0s border, .1s background ease;
    }
    
    .submenu ul li:hover, .submenu ul li.active {
        background: #f4f4f4;
        border-bottom: 1px solid #f4f4f4;
    }

    .submenu ul li:hover {
        cursor: pointer;
    }

    .submenu ul li a {
        color: #000000;
        transition: 0s border, .3s text-indent ease;
        padding: 0 0 0 0;
        border-bottom: 1px solid #bfbfbf;
        margin-right: 20px;
    }

    .submenu ul li a:after {
        display: none;
    }

    .submenu ul li a.hassubmenu:after {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        background-image: url('./../assets/images/ChevronRight.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        /*margin: 0 0 -1.5px 4px;*/
        position: absolute;
        right: 10px;
        top: 36%;
        /*filter: invert(0);*/
        transform: rotate(-0deg);
    }

    .submenu ul li a:hover, .submenu ul li.active a {
        text-decoration: underline;
        text-indent: 16px;
        background: none;
        border: none;
    }

    .submenu li.end:after {
        display: none;
    }

    .submenu li.end:hover {
        background: none;
        border: none;
    }

    .submenu li.end a:hover {
        border-bottom: 1px solid #bfbfbf;
    }

    .sub-submenu {
        position: absolute;
        left: 280px;
        right: 0;
        background: #f4f4f4;
        top: 0;
        /*bottom: 0;*/
        padding: 10px 20px 20px 20px;
        opacity: 0;
        visibility: hidden;
        min-height: 82px;
    }

    .sub-submenu.active {
        opacity: 1;
        visibility: visible;
    }

    .sub-submenu ul li, .sub-submenu ul li:hover {
        border: none;
    }

    .sub-submenu ul li a {
        transition: all 0s;
        text-indent: 0;
        background: none;
        border: none;
        text-decoration: none !important;
        text-indent: 0 !important;
    }

    .sub-submenu ul li a:hover {
        border: none;
        text-indent: 0;
        margin-right: 0;
        text-decoration: none !important;
        color: #000000;
    }

    .search-trigger {
        margin: 0 auto;
        max-width: 930px;
        position: relative;
    }

    .trigger {
        /*background-position: 11px -218px;*/
        width: 48px;
        height: 48px;        
        display: block;
        content: '';
        /*text-decoration: none;*/
        /*text-indent: -9999px;*/
        
        /*background-repeat: no-repeat;*/
        /*background-image: url('./../assets/images/Search.png');*/
        /*background-size: 300px 300px;*/
        /*background-color: transparent;*/
        margin: auto;
        border: none;
        transition: all .3s;
        position: absolute;
        right: 0;
        top: -48px;
        filter: invert(1);
        /*background-size: contain;*/
    }
    .trigger svg {
       width: 58%;
        height: auto; 
    }

    .trigger.active {
        background-color: #FFFFFF;
        filter: invert(0);
    }

    .trigger:hover {
        cursor: pointer;
        /*background-color: var(--charcoal);*/
    }
</style>